import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { CssBaseline, Box } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

import Home from "./pages/home/home";
import Devops from "./pages/devops/devops";
import MobileApps from "./pages/MobileApps/MobileApps";
import Landings from "./pages/Landings/Landings";
import CloudNative from "./pages/CloudNative/CloudNative";
import UxUiDesign from "./pages/UxUiDesign/UxUiDesign";
import CorporateSites from "./pages/CorporateSites/CorporateSites";
import CrmSystems from "./pages/CrmSystems/CrmSystems";

import theme from "./kit/theme";
import ScrollToTop from "./kit/ScrollToTop";
import { ContactFormModal } from "./kit/ContactForm";
import { SnackbarProvider } from "notistack";

const A = () => {
  return <div id="apena" style={{width: "100vw", height: "100vh"}}></div>
}
const B = () => {
  return <div id="apena" style={{width: "1440px", height: "704px"}}></div>
}
const C = () => {
  return <div id="apena" style={{width: "1000px", height: "1173px"}}></div>
}
const D = () => {
  return <div id="apena" style={{width: "600px", height: "600px"}}></div>
}
const E = () => {
  return <div id="apena" style={{width: "375px", height: "812px"}}></div>
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <CssBaseline />
    <ThemeProvider theme={theme}>
      <Box overflow="hidden">
        <BrowserRouter>
          <ContactFormModal />
          <SnackbarProvider />
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/a" element={<A />} />
            <Route path="/b" element={<B />} />
            <Route path="/c" element={<C />} />
            <Route path="/d" element={<D />} />
            <Route path="/e" element={<E />} />
            <Route path="/devops" element={<Devops />} />
            <Route path="/mobileapps" element={<MobileApps />} />
            <Route path="/landings" element={<Landings />} />
            <Route path="/cloudnative" element={<CloudNative />} />
            <Route path="/design" element={<UxUiDesign />} />
            <Route path="/corporatesites" element={<CorporateSites />} />
            <Route path="/crmsystems" element={<CrmSystems />} />
          </Routes>
        </BrowserRouter>
      </Box>
    </ThemeProvider>
  </React.StrictMode>
);
