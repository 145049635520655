import {Typography, useMediaQuery, Box, useTheme, Button} from "@mui/material";

import {PenaLogo} from "../Icons/Logo/logoPena";

export default function Component() {
  const theme = useTheme();

  const isTablet = useMediaQuery(theme.breakpoints.down("xl"));
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box
      component="footer"
      sx={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        height: isMobile ? "290px" : "236px",
        position: "relative",
        zIndex: theme.zIndex.content,
        backgroundColor: "#252734",
        padding: isTablet ? (isMobile ? "40px 18px" : "60px 0 0 0") : " 60px 0 0 70px",
        justifyContent: "normal",
        alignItems:  "normal",
      }}
    >
      <Box sx={{ ml: isMobile ? "0" : isTablet ? "40px" : "70px", mt: isMobile ? 0 : "10px" }}>
          <PenaLogo
              style={{
                  height: 48,
                  color: "white",
                  width: 124
              }}
          />
        <Typography sx={{ color: "rgba(114, 112, 116, 1)", fontSize: "14px" }}>ООО Пена © 2023</Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: isMobile ? "40px" : 0,
          ml: isMobile ? "0" : "167px",
        }}
      >
          <Button variant="text" sx={{fontSize: "16px", fontWeight: 500, color: "white", textTransform: "none", justifyContent: "flex-start"}} href={"https://hub.pena.digital/docs/oferta"}>
              Пользовательское соглашение
          </Button>
          <Button variant="text" sx={{fontSize: "16px", fontWeight: 500, color: "white", textTransform: "none", justifyContent: "flex-start"}} href={"https://hub.pena.digital/docs/privacy"}>
              Политика конфиденциальности
          </Button>
      </Box>
    </Box>
  );
}
