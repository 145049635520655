import { FC, SVGProps } from "react";

export const PenaLogo: FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg
        {...props}
        width="1986"
        height="644"
        viewBox="0 0 1986 644"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">

        <path fillRule="evenodd" clipRule="evenodd" d="M275.706 0.985583C194.151 -8.46131 137.886 79.143 85.5512 142.468C39.6223 198.042 5.42438 260.813 1.4522 332.832C-2.77384 409.454 11.1252 489.251 63.3251 545.447C117.094 603.331 197.637 639.905 275.706 628.08C347.293 617.237 378.97 538.624 432.267 489.565C490.137 436.296 597.954 411.52 596.458 332.832C594.96 254.024 481.171 238.598 426.42 181.953C369.708 123.279 356.721 10.37 275.706 0.985583Z" fill="#7E2AEA"/>

        <circle cx="478.638" cy="598.134" r="45.0013" fill="#7E2AEA"/>

        <circle cx="433.634" cy="98.909" r="17.1434" fill="#7E2AEA"/>

        <path d="M707.867 314.43C702.868 265.833 680.005 220.822 643.708 188.123C607.412 155.423 560.268 137.364 511.415 137.446C504.479 137.449 497.547 137.811 490.649 138.533C442.074 143.633 397.108 166.54 364.423 202.834C331.739 239.129 313.652 286.241 313.65 335.083V335.083V617.422H398.352V497.428C431.469 520.467 470.86 532.786 511.203 532.721C518.139 532.718 525.071 532.355 531.969 531.634C557.782 528.921 582.807 521.15 605.616 508.766C628.426 496.381 648.572 479.626 664.906 459.455C681.24 439.284 693.44 416.094 700.812 391.208C708.183 366.322 710.58 340.228 707.867 314.416V314.43ZM599.081 406.19C589.777 417.748 578.272 427.345 565.232 434.426C552.193 441.507 537.879 445.93 523.118 447.44C519.16 447.851 515.183 448.058 511.203 448.061C485.587 448.038 460.74 439.306 440.74 423.3C420.741 407.294 406.776 384.965 401.14 359.976C395.503 334.988 398.529 308.826 409.72 285.784C420.912 262.742 439.604 244.19 462.73 233.171C485.855 222.153 512.039 219.323 536.984 225.147C561.929 230.971 584.153 245.103 600.009 265.222C615.864 285.341 624.409 310.253 624.24 335.869C624.072 361.484 615.2 386.281 599.081 406.19V406.19Z" fill="currentColor"/>

        <path d="M928.858 137.445C921.922 137.448 914.991 137.811 908.092 138.532C857.76 143.833 811.38 168.24 778.51 206.723C745.641 245.206 728.787 294.833 731.422 345.374C734.058 395.916 755.982 443.52 792.675 478.376C829.369 513.232 878.036 532.683 928.646 532.72C935.583 532.718 942.514 532.355 949.412 531.633C985.702 527.77 1020.22 513.965 1049.17 491.739C1078.11 469.513 1100.36 439.729 1113.47 405.668H1016.92L1016.52 406.162C1004.16 421.447 988.015 433.24 969.692 440.372C951.37 447.505 931.503 449.73 912.056 446.828C892.61 443.926 874.258 435.997 858.816 423.826C843.374 411.656 831.377 395.664 824.012 377.434H1121.88C1128.15 348.578 1127.89 318.684 1121.11 289.943C1114.33 261.203 1101.2 234.343 1082.7 211.332C1064.19 188.322 1040.77 169.743 1014.15 156.959C987.53 144.174 958.388 137.507 928.858 137.445V137.445ZM824.012 292.732C831.722 273.753 844.438 257.222 860.804 244.901C877.17 232.579 896.572 224.93 916.943 222.768C920.902 222.357 924.879 222.15 928.858 222.147C951.356 222.109 973.348 228.814 991.999 241.395C1010.65 253.976 1025.1 271.858 1033.49 292.732H824.012Z" fill="currentColor"/>

        <path d="M1331.11 137.446C1298.74 137.394 1266.98 146.296 1239.35 163.167V137.446H1154.65V532.721H1239.35V313.908C1239.35 289.571 1249.02 266.232 1266.22 249.023C1283.43 231.815 1306.77 222.147 1331.11 222.147C1355.44 222.147 1378.78 231.815 1395.99 249.023C1413.2 266.232 1422.87 289.571 1422.87 313.908V532.721H1507.57V313.908C1507.57 267.107 1488.98 222.223 1455.89 189.13C1422.79 156.037 1377.91 137.446 1331.11 137.446V137.446Z" fill="currentColor"/>

        <path d="M1931.1 360.338V137.446H1846.4V172.738C1813.28 149.689 1773.89 137.369 1733.55 137.446C1726.61 137.449 1719.68 137.812 1712.78 138.533C1662.45 143.833 1616.07 168.24 1583.2 206.724C1550.33 245.207 1533.47 294.833 1536.11 345.375C1538.74 395.916 1560.67 443.521 1597.36 478.376C1634.06 513.232 1682.72 532.683 1733.33 532.721C1740.27 532.718 1747.2 532.355 1754.1 531.634C1803.33 526.596 1848.83 503.144 1881.51 465.976C1906.89 499.937 1943.65 523.629 1985.07 532.721V443.925C1968.99 436.646 1955.36 424.892 1945.79 410.07C1936.22 395.247 1931.12 377.982 1931.1 360.338V360.338ZM1821.21 406.162C1811.91 417.718 1800.4 427.313 1787.36 434.394C1774.32 441.474 1760.01 445.899 1745.25 447.412C1741.29 447.823 1737.31 448.03 1733.33 448.033C1704.39 448.032 1676.56 436.922 1655.58 416.996C1634.59 397.07 1622.05 369.849 1620.55 340.95C1619.05 312.05 1628.71 283.677 1647.52 261.687C1666.33 239.696 1692.86 225.765 1721.64 222.769C1725.6 222.357 1729.57 222.15 1733.55 222.147C1754.87 222.166 1775.75 228.22 1793.78 239.61C1811.8 251 1826.23 267.26 1835.41 286.51C1844.58 305.76 1848.11 327.213 1845.6 348.387C1843.09 369.561 1834.63 389.592 1821.21 406.162V406.162Z" fill="currentColor"/>

    </svg>
)