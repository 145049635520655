import { useState } from "react";
import { Typography, Box, Button, useMediaQuery, useTheme } from "@mui/material";

import Section from "../../../kit/section";

import Bubbles from "./Bubbles";

import {PenaLogo} from "../../../Icons/Logo/logoPena";
import BubblesMobil from "../../../image/img_landings/img_bubbles_2_mobile.png";
import {setIsContactFormOpen} from "../../../stores/contactForm";

export default function Section6() {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.down(1000));

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <Section
      mwidth="1200px"
      tag="section"
      bg="#333647"
      sxsect={{
        padding: `${isMobile ? "50px 0" : "138px 0"}`,
        overflow: "hidden",
      }}
      sxcont={{
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "relative",
          zIndex: theme.zIndex.content,
        }}
      >
        <Typography variant="h1" color="white">
          Закажите эффективный лендинг в
            <PenaLogo
                style={{
                    height: isMobile ? 42 : 71,
                    marginLeft: "15px",
                    verticalAlign: "text-bottom",
                    color: "white",
                    width: isMobile ? 124 : 185
                }}
            />
        </Typography>
        <Button
        onClick={() => setIsContactFormOpen(true)}
          disableRipple
          variant="connect"
          sx={{
            mt: "40px",
            padding: "9px 50px",
          }}
        >
          Заказать
        </Button>
      </Box>
      {isMobile ? (
        <Box
          component="img"
          src={BubblesMobil}
          sx={{ position: "absolute", bottom: `${isMobile ? "-50px" : "-138px"}`, left: "188px" }}
        />
      ) : (
        <Bubbles
          style={{
            position: "absolute",
            top: "-44px",
            right: isTablet ? "-629px" : "-501px",
            pointerEvents: "none",
          }}
        />
      )}
    </Section>
  );
}
