import { CSSProperties, useState } from "react";
import { Typography, Box, SxProps, Theme, useMediaQuery, useTheme } from "@mui/material";

import ArrowButtons from "../../../kit/ArrowButtons";
import SkeletonImage from "../../../kit/SkeletonImage";

import {PenaLogo} from "../../../Icons/Logo/logoPena";

const SLIDER_ITEMS_GAP = "168px";

export interface SliderItem {
  image: string;
  headerText: string;
  text?: string;
  color: string;
  backgroundColor: string;
  backgroundPosition: string;
  sx?: SxProps<Theme>;
}

interface Props {
  items: SliderItem[];
  sx?: SxProps<Theme>;
  sxSliderElement?: SxProps<Theme>;
}

export default function Slider({ items, sx }: Props) {
  const [activeItemIndex, setActiveItemIndex] = useState<number>(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(600));

  const calcRound = (step: number): number => {
    //Функция посчитает дошли ли мы до края. Если да - ведут в противоположный
    let calc = activeItemIndex + step;
    let imgCount = items.length - 1;
    if (calc > imgCount) {
      return 0;
    }
    if (calc < 0) {
      return imgCount;
    }
    return calc;
  };
  const leftScroll = () => {
    setActiveItemIndex(calcRound(-1));
  };
  const rightScroll = () => {
    setActiveItemIndex(calcRound(1));
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "30px",
        ...sx,
      }}
    >
      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          borderRadius: "8px",
          overflow: "hidden",
          position: "relative",
          height: "400px",
        }}
      >
        {items.map((sliderItem, index) => (
          <SliderElement
            sliderItem={sliderItem}
            key={index}
            style={{
              left: `calc((100% + ${SLIDER_ITEMS_GAP}) * ${index - activeItemIndex})`,
            }}
            sx={{
              ...sliderItem.sx,
            }}
          />
        ))}
        <ArrowButtons
          leftScroll={leftScroll}
          rightScroll={rightScroll}
          sx={{
            width: isMobile ? "100%" : "140px",
            display: "flex",
            justifyContent: "space-between",
            position: "absolute",
            mb: "10px",
            bottom: isMobile ? "149px" : "0",
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          maxWidth: "510px",
          gap: "calc(20 / 510 * 100%)",
          alignSelf: "center",
        }}
      >
        {items.map((image, index) => (
          <Box
            key={index}
            onClick={() => setActiveItemIndex(index)}
            style={{
              backgroundColor: activeItemIndex === index ? "#7E2AEA" : "#7D7D7D33",
              flex: activeItemIndex === index ? "1.8 0 0" : "1 0 0",
            }}
            sx={{
              height: "5px",
              p: 0,
              transitionDuration: "300ms",
              transitionTimingFunction: "ease-in-out",
              transitionProperty: "background-color, flex",
              cursor: "pointer",
              borderRadius: "9px",
            }}
          />
        ))}
      </Box>
    </Box>
  );
}

function SliderElement({
  sliderItem,
  style,
  sx,
}: {
  sliderItem: SliderItem;
  style?: CSSProperties;
  sx: SxProps<Theme>;
}) {
  const theme = useTheme();
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <SkeletonImage
      image={sliderItem.image}
      width="100%"
      height="100%"
      sx={{ ...sx }}
      Children={
        <Box
          style={style}
          sx={{
            position: "absolute",
            top: 0,
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            p: "20px",
            transitionDuration: "800ms",
            transitionTimingFunction: "ease-in-out",
            transitionProperty: "left",
            borderRadius: "8px",
            backgroundPosition: "center bottom",
            backgroundSize: "264%",
            backgroundRepeat: "no-repeat",
            backgroundImage: `url(${sliderItem.image})`,
            backgroundColor: sliderItem.backgroundColor,
            ...sx,
          }}
        >
          <Typography
            variant={downLg ? "h4" : "h3"}
            sx={{ fontSize: "20px" }}
            maxWidth="441px"
            color={sliderItem.color}
          >
            {sliderItem.headerText}
          </Typography>
          <Typography variant="t1" mt="20px" maxWidth="441px" color={sliderItem.color}>
            {sliderItem.text}
          </Typography>

          {!downLg && (
              <PenaLogo
                  style={{
                      marginTop: "auto",
                      width: 180,
                      height: 70,
                      opacity: 0.2,
                      color: "#151515",
                  }}
              />
          )}
        </Box>
      }
    />
  );
}
